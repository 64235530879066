body {
    background: #131418;
}

.oracle {
    $modal-header-height: 60px;
    $modal-content-bottom-padding: 15px;
    @mixin mobile-menu-btn {
        background: none;
        left: 20px;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;

    &__wrapper {
        font-family: 'SF Pro Text';
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        height: 100vh;
        overflow: scroll;
        &.meta-crash {
            background-color: #131418;
        }
        &.air-jet {
            background-color: #0A0F1E;
        }
    }

    .logo-wrapper {
        position: relative;

        &.big {
        margin-bottom: 30px;
        @media screen and (max-width: 725px) {
            margin-bottom: 15px;
        }
        }

        &.small {
            margin-bottom: 20px;
        }
    } 

    @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
      
        to {
          transform: rotate(360deg);
        }
    }

    .logo {
        position: relative;
        height: 180px;
        width: 180px;
        z-index: 1;
        @media screen and (max-width: 725px) {
            height: 140px;
            width: 140px;
        }
        &.spinning {
            animation: 3s linear infinite rotate;
        }
        &.small {
            width: 50px;
            height: 50px;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }

    .logo-light {
        position: absolute;
        top: -135px;
        left: -260px;
        background: radial-gradient(50% 50% at 50% 50%, #151B2E 0%, rgba(21, 27, 46, 0) 100%);
        width: 700px;
        height: 450px;
        @media screen and (max-width: 725px) {
            width: 350px;
            left: -105px;
        }
    }

    &__connection-error {
        width: 100%;
        height: 40px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
        left: 0;
        background: #F96057;
        z-index: 3;
        .text__small {
            color: #fff;
        }
        @media screen and (max-width: 725px) {
            top: 0;
        }
    }

    .text {
        &__header {
            font-size: 25px;
            line-height: 30px;
            font-weight: 700;
            color: #fff;
            text-align: center;
        }

        &__state {
            font-size: 20px;
            line-height: 25px;
            font-weight: 600;
            text-align: center;
            &.meta-crash {
                color: #f19f35;
            }
            &.air-jet {
                color: #5FBFA6;
            }
        }

        &__regular {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #FFFFFFCC;
            text-align: center;
        }

        &__small {
            font-size: 13px;
            font-weight: 400;
            line-height: 17px;
            color: #77829B;
            text-align: center;
            
            &.aside-lines  {
                position: relative;
                &:after, &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    width: 70px;
                    border: 0.5px solid #97AEE11C;
                }
                &:after {
                    left: 2px;
                }
                &:before {
                    right: 2px;
                }
            }
        }
        &__error {
            font-size: 11px;
            font-weight: 400;
            line-height: 15px;
            text-align: left;
            margin-top: 3px;
            color: #F96057;
            &.empty {
                color: #fff;
            }
        }
    }

    &__toggle-wrapper {
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        top: 50px;
        font-size: 13px;
        font-weight: 510;
        &.air-jet {
            right: calc(50% - 50px);
            color: #5FBFA6;
        }
        &.meta-crash {
            right: calc(50% - 70px);
            color: #f19f35;
        }
        &.meta-crash, &.air-jet {
            @media screen and (max-width: 725px) {
                top: 30px;
                right: 20px;
            }
            @media screen and (max-height: 850px) {
                top: 30px;
                right: 20px;
            }
        }
    }

    &__button, &__input {
        border-radius: 10px;
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 725px) {
            height: 40px;
        }
        &.big {
            height: 65px;
            @media screen and (max-width: 725px) {
                height: 55px;
            }
        }
    }

    &__button {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #fff;
        @media screen and (max-width: 725px) {
            font-size: 14px;
        }
        &.big {
            font-size: 17px;
            @media screen and (max-width: 725px) {
                font-size: 16px;
            }
        }
        &:active {
            opacity: 80%;
        }
        &.disabled {
            opacity: 50%;
        }
        &.secondary {
            background: #97AEE11C;
        }
        &.telegram {
            background: #2AABEE;
        }
        &.wrong {
            background: #F96057;
        }
        &.like {
            width: 140px;

            &.selected {
                background: linear-gradient(88.93deg, #31BC69 1.83%, #089E4E 98.17%);

                .thumb-up {
                    color: #fff;
                }
            }

            .thumb-up {
                color: #12B857;
            }
        }
        &.dislike{
            width: 140px;

            &.selected {
                background: #F96057;

                .thumbs-down {
                    color: #fff;
                }
            }

            .thumbs-down {
                color: #F3492B;
            }
        }
        &.menu {
            position: absolute;
            z-index: 2;
            left: calc(50% - 50px);
            padding: 13px 20px;
            width: fit-content;
            top: 30px;
            background: #97AEE11C;
            @media screen and (max-width: 725px) {
                @include mobile-menu-btn()
            }
            @media screen and (max-height: 850px) {
                @include mobile-menu-btn()
            }
            .oracle__icon {
                margin-right: 8px;
            }
        }
        &.toggle-game {
            padding: 5px 10px;
            font-weight: 600;
            letter-spacing: 0.5px;
            height: fit-content;
            width: fit-content;
        }
        &.air-jet {
            background: linear-gradient(88.93deg, #31BC69 1.83%, #089E4E 98.17%);
            .oracle__toggle-icon {
                margin-top: -3px;
            }
        }
        &.meta-crash {
            background: linear-gradient(88.93deg, #ffa125 1.83%, #f56e34 98.17%);
        }
    }

    &__icon {
        margin-right: 5px;
    }

    &__toggle-icon {
        width: 20px;
    }

    &__input {
        background: #97AEE11C;
        padding: 13px 16px;
        font-size: 13px;
        font-weight: 400;
        line-height: 17px;
        text-align: left;
        color: #FFFFFF;
        @media screen and (max-width: 725px) {
            padding: 10px 16px;
        }
        &.error {
            color: #F96057;
        }
        &::placeholder {
            font-size: 13px;
            font-weight: 400;
            line-height: 17px;
            text-align: left;
            color: #77829B;
        }
    }

    .column {
        display: flex;
        flex-direction: column;
        width: 345px;
    }

    .login-screen {
        gap: 35px;
        z-index: 1;
        &.disabled {
            opacity: 50%;
        }
       .body {
        gap: 15px;
       } 
    }

    &__modal {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100dvw;
        height: 100dvh;
        background: rgba(9, 15, 30, 0.7);
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        opacity: 0;
        transition: 0.5s;
        z-index: 99;

        &.open {
            opacity: 1;
        }

        .wrapper {
            padding: 0 5px 0 20px;
            background: #fff;
            width:100%;
            height: 100%;
            border-radius: 20px 20px 0 0;

            @media screen and (min-width: 726px) {
                width: 90%;
                height: 90%;
                border-radius: 20px;
            }

            ::-webkit-scrollbar{
                width: 5px;
                position: fixed;
                right: 5px;

                &-track {
                    background: transparent;
                }
                &-thumb {
                    background: #EDF0F7;
                    height: 100px;
                    border-radius: 23px;
                }
            }

            ::-webkit-scrollbar-track-piece:start{
                margin-top:15px;
            }
        }

        .header {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            column-gap: 30px;
            place-items: center;
            height: $modal-header-height;
            border-bottom: 1px #97AEE11C solid;
            margin-top: 15px;
            padding-right: 15px;

            .close-button {
                grid-column: 1;
                color: #1A88F3;
                font-size:14px;
                font-weight: 600;
                line-height: 20px;
                place-self: center start;
                display: unset;

                @media screen and (min-width: 726px) {
                    display: none;
                }
            }

            .close-button-icon {
                grid-column: 3;
                place-self: center end;
                display: none;

                @media screen and (min-width: 726px) {
                    display: block;
                }
            }

            &-text{
                grid-column: 2;
                font-size:16px;
                font-weight: 600;
                line-height: 24px;
                text-align: center;
            }
        }

        .content {
            width: 100%;
            height: calc(100% - $modal-header-height - $modal-content-bottom-padding);
            padding-right: 10px;
            padding-bottom: $modal-content-bottom-padding;
            overflow-y: scroll;

            &-title-wrapper{
                margin: 15px 0;

                .title{
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 31px;
                    text-align: left;
                }
            }

            .text {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                
                &.bold {
                    font-weight: 600;
                }
            }

            .video {
                border-radius:15px;
                width: 100%;
                height: 400px;
                margin: 15px 0;
                @media screen and (max-width: 725px) {
                    height: 250px;
        }
                iframe {
                    border-radius: 15px;
                }

                .preview {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 15px;
                }

                .react-player__play-icon {
                    display: none;
                }

                .react-player__shadow{
                    display: none !important;
                }

                &-details{
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 17px;
                    text-align: left;
                    margin-top: 8px;
                    margin-bottom: 15px;
                }
            }
        }
    }

    .loading-screen {
        align-items: center;
        justify-content: center;
        gap: 15px;
        z-index: 1;
    }

    .main-screen {
        align-items: center;
        justify-content: center;
        gap: 15px;
        z-index: 1;
        &.disabled {
            opacity: 50%;
        }

        .main-info-wrapper{
            display: grid;
            padding: 20px;
            grid-template-rows: repeat(2, 1fr);
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas:
                "connection online"
                "button button";
            border-radius: 15px;
            gap: 20px;
            background: #141B2E;
            color: #fff;
            width: 345px;
            height: 170px;
            @media screen and (max-width: 725px) {
                height: 160px;
            }

            .connection{
                grid-area: connection;
                display: flex;
                flex-direction: column;
                row-gap:5px;
                justify-content: space-between;
                position: relative;

                &:after{
                    content: "";
                    height: 35px;
                    width:1px;
                    position: absolute;
                    right: 0;
                    background-color: #97AEE11C;
                }
            }
            .online {
                grid-area: online;
                display: flex;
                flex-direction: column;
                row-gap: 5px;
                justify-content: space-between;
            }
            .button-wrapper {
                grid-area: button;
            }

            .title{
                font-size: 13px;
                font-weight: 510;
                line-height: 17px;
                text-align: left;
            }

            .info-wrapper{
                display: flex;
                column-gap: 7px;
                align-items: center;

                .icon {
                    width: 15px;
                    height: 15px;
                    img{
                        width:100%;
                        height:100%;
                        vertical-align: unset;
                    }
                }
            }

            .status {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                &.offline {
                    color: #77829B;
                    font-weight: 700;
                }
            }

            &.air-jet {
                .title {
                    color: #5FBFA6;
                }
            }
            &.meta-crash {
                .title {
                    color: #f19f35;
                }
            }

        }
    }

    &__progress-container, &__progress-filler {
        width: 149px;
        height: 6px;
        border-radius: 18px;
    }

    &__progress-container {
        background: #141B2E;
    }

    &__progress-filler {
        position: relative;
        transition: 0.1s linear;
        &::after {
            content: '';
            position: absolute;
            right: -6px;
            top: -6px;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-image: url('../assets/svgs/ellipse.svg');
        }
        &.meta-crash {
            background: linear-gradient(88.93deg, #ffa125 1.83%, #f56e34 98.17%);
        }
        &.air-jet {
            background: linear-gradient(90deg, #5FBFA6 -0.57%, #07A57C 99.43%);
        }
    }

    .rate-screen {
        z-index: 1;
        gap: 35px;
        align-items: center;
        justify-content: center;
        &.disabled {
            opacity: 50%;
        }

        .rate-block {
            display: flex;
            position: relative;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 345px;
            padding: 20px;
            border-radius: 15px;
            gap: 20px;
            background: #141B2E;
            color: #fff;
            margin-top: -15px;
            @media screen and (max-width: 725px) {
                margin-top: -10px;
            }
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 60px;
                width: 345px;
                border: 1px solid #97AEE11C;
            }

            .rate {
                font-size: 50px;
                font-weight: 700;
                line-height: 50px;
                margin-bottom: 20px;
            }
            .text__regular {
                color: #fff;
                .green {
                    color: #5FBFA6;
                }
            }
        }

        .like-block {
            display: flex;
            gap: 5px;
            margin-bottom: 12px;
        }
    }
    &.error-screen {
        .logo-wrapper {
            margin-bottom: 15px;
        }

        .description-block {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            row-gap: 10px;
            margin-bottom: 35px;
            //because .logo-light overlap this block
            z-index: 2;

            .title {
                color: #FFF;
                font-size: 25px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: -0.025em;
                text-align: left;
            }
            .subtitle {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: #FFFFFFCC;
            }
        }
        .info-block {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 345px;
            height: 150px;
            border-radius: 15px;
            padding:15px;
            background-color: #141B2E;
            margin-bottom: 35px;

            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                letter-spacing: -0.23000000417232513px;
                color: #FFFFFFCC;
            }
        }
        .restore-access-block {
            width: 100%;
        }
    }

    .margin-top-12 {
        margin-top: 12px;
    }

    .margin-bottom-10 {
        margin-bottom: 10px;
    }

}
