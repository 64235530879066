
@font-face {
    font-family: 'SF Pro Text';
    src: url('../assets/fonts/sf-pro-text/SFProText-Bold.ttf');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../assets/fonts/sf-pro-text/SFProText-Semibold.ttf');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../assets/fonts/sf-pro-text//SFProText-Medium.ttf');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('../assets/fonts/sf-pro-text//SFProText-Regular.ttf');
    font-style: normal;
    font-weight: 400;
}